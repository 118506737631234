import React, { Component } from "react";

class LocationButton extends Component {
  render() {
    return (
      <button
        className=""
        name="selectLocation"
        value={this.props.value}
        onClick={this.props.onClick}
      >
        {this.props.city}
      </button>
    );
  }
}

export default LocationButton;
