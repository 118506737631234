import React, { Component } from "react";
import { getWindDirection } from "../functions/getWindDirection.js";
import moment from "moment";

class Observations extends Component {
  render() {
    let data, sunrise, sunset, timezone, sunriseTime, sunsetTime, moonPhase;
    const cityData = this.props.cityData;
    if (!cityData) return null;
    if (this.props.pageType === "current") {
      data = cityData.currently;
      sunriseTime = cityData.daily.data[0].sunriseTime;
      sunsetTime = cityData.daily.data[0].sunsetTime;
      timezone = cityData.timezone;
      sunrise = moment
        .unix(sunriseTime)
        .tz(timezone)
        .format("h:mm");
      sunset = moment
        .unix(sunsetTime)
        .tz(timezone)
        .format("h:mm");
      moonPhase = Math.round(cityData.daily.data[0].moonPhase * 100);
    } else if (this.props.pageType === "forecast") {
      let dayNumber = Number(this.props.day);
      data = cityData.daily.data[dayNumber];
      if (!data) return null;
      sunriseTime = data.sunriseTime;
      sunsetTime = data.sunsetTime;
      timezone = cityData.timezone;
      sunrise = moment
        .unix(sunriseTime)
        .tz(timezone)
        .format("h:mm");
      sunset = moment
        .unix(sunsetTime)
        .tz(timezone)
        .format("h:mm");
      moonPhase = Math.round(data.moonPhase * 100);
    }
    const windDirection = getWindDirection(data.windBearing);
    const defaultPercipType = data.temperature > 32 ? "rain" : "snow";
    const precipType = data.precipType ? data.precipType : defaultPercipType;

    //Display the correct units.
    let windUnits, tempUnits, pressureUnits, visibilityUnits, accumUnits;
    if (this.props.units === "us") {
      windUnits = "mph";
      tempUnits = "F";
      pressureUnits = "mb";
      visibilityUnits = "mi";
      accumUnits = "inches";
    } else {
      windUnits = "mps";
      tempUnits = "C";
      pressureUnits = "hpa";
      visibilityUnits = "km";
      accumUnits = "mm";
    }

    return (
      <div className="hidden lg:flex justify-center w-full bg-grey-lightest">
        <div className="w-5/6 flex justify-center bg-white py-6 ">
          <table className="w-11/12">
            <tbody>
              <tr className="border-b border-grey-light">
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Sunrise
                </td>
                <td className="w-1/6 p-3 bg-white">{sunrise} am</td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Sunset
                </td>
                <td className="w-1/6 p-3 bg-white">{sunset} pm</td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Moon Phase
                </td>
                <td className="w-1/6 p-3 bg-white">{moonPhase} %</td>
              </tr>

              <tr className="border-b border-grey-light">
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Wind
                </td>
                <td className="w-1/6 p-3 bg-white ">
                  {Math.round(data.windSpeed)} {windUnits}
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Humidity
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.humidity * 100)} %
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Chance of {precipType}
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.precipProbability * 100)} %
                </td>
              </tr>

              <tr className="border-b border-grey-light">
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Wind From Direction
                </td>
                <td className="w-1/6 p-3 bg-white">{windDirection}</td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Dew Point
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.dewPoint)}&deg;{tempUnits}
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Hourly Accum
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {data.precipIntensity.toFixed(2)} {accumUnits}
                </td>
              </tr>

              <tr className="border-b border-grey-light">
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Wind Gusts
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.windGust)} {windUnits}
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Pressure
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.pressure)} {pressureUnits}
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  UV Index
                </td>
                <td className="w-1/6 p-3 bg-white">{data.uvIndex}</td>
              </tr>

              <tr className="border-b-2 border-white">
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Cloud Cover
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.cloudCover * 100)} %
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Visibility
                </td>
                <td className="w-1/6 p-3 bg-white">
                  {Math.round(data.visibility)} {visibilityUnits}
                </td>
                <td className="w-1/6 p-3 bg-white text-grey-darker font-bold">
                  Ozone
                </td>
                <td className="w-1/6 p-3 bg-white">{Math.round(data.ozone)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Observations;
