import React, { Component } from "react";

class ErrorNotice extends Component {
  render() {
    const iconImage = require("../images/rain_day.png");

    return (
      <div>
        <div className="flex justify-center mt-8 ">
          <img src={iconImage} alt="icon" />
        </div>
        <div className="flex justify-center pb-4 mb-32 px-16 text-5xl font-bold text-grey-dark">
          The DarkSky API is not currently available.
        </div>
      </div>
    );
  }
}

export default ErrorNotice;
