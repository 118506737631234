import React, { Component } from "react";
import HourlyForecastRow from "../components/HourlyForecastRow";
import moment from "moment-timezone";
import _ from "lodash";

class HourlyForecast extends Component {
  render() {
    const cityData = this.props.cityData;
    if (!cityData) return null;
    let hours;
    let timezone = cityData.timezone;
    // There are a variable number of elements for the
    // current day in the data array, depending on the time of day.
    // Remove elements for the current day from the data array.
    // Save those in the todaysData array. Then the data
    // object will only have elements for the days after
    // today.  data[0] will represent midnight tomorrow.
    const now = moment().tz(timezone);
    let data = _.cloneDeep(cityData.hourly.data);
    let todaysData = _.remove(data, function(h) {
      return now.isSame(moment.unix(h.time).tz(timezone), "day");
    });
    // If today's data is being shown, use the elements from
    // the todaysData array. Otherwise, use the appropriate
    // slice from the data array depending upon day being shown.
    if (this.props.day === "0") {
      hours = todaysData;
      //title = "Today's Outlook"
    } else {
      const dayNumber = Number(this.props.day);
      const start = (dayNumber - 1) * 24;
      const end = start + 24;
      hours = _.slice(data, start, end);
    }
    //}

    return (
      <div>
        <div className="hidden lg:flex justify-center w-full bg-grey-lightest">
          <div
            className={
              this.props.componentWidth + " flex justify-center bg-white pb-12"
            }
          >
            <table className="w-11/12">
              <thead className="font-bold text-grey-darker">
                <tr className="">
                  <th className="w-1/10 p-3 bg-grey-light text-left">Time</th>
                  <th className="w-1/5 p-3 bg-grey-light text-left">Summary</th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">Temp</th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Feels Like
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Ch Prec
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Prec Amt
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Cloud Cover
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Wind Speed
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Wind Dir
                  </th>
                </tr>
              </thead>
              <tbody>
                {hours.map((h, i) => (
                  <HourlyForecastRow
                    key={i.toString()}
                    index={i}
                    data={h}
                    timezone={timezone}
                  />
                ))}
              </tbody>
              <tfoot className="font-bold text-grey-darker">
                <tr>
                  <th className="w-1/10 p-3 bg-grey-light text-left">Time</th>
                  <th className="w-1/5 p-3 bg-grey-light text-left">Summary</th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">Temp</th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Feels Like
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Ch Prec
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Prec Amt
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Cloud Cover
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Wind Speed
                  </th>
                  <th className="w-1/10 p-3 bg-grey-light text-left">
                    Wind Dir
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default HourlyForecast;
