import React, { Component } from "react";

class Alerts extends Component {
  render() {
    const alerts = this.props.cityData.alerts;
    if (!alerts) return null;

    return (
      <div>
        <div className="text-sm mb-1 font-bold">
          <a
            className="no-underline text-red"
            href={alerts[0].uri}
            target="_blank"
            rel="noopener noreferrer"
          >
            {alerts[0].title}
          </a>
        </div>
      </div>
    );
  }
}

export default Alerts;
