import _ from "lodash";

export function getWindDirection(bearing) {
  const compassValues = [
    {
      low: 348.75,
      high: 360,
      direction: "N"
    },
    {
      low: 0,
      high: 11.25,
      direction: "N"
    },
    {
      low: 11.25,
      high: 33.75,
      direction: "NNE"
    },
    {
      low: 33.75,
      high: 56.25,
      direction: "NE"
    },
    {
      low: 56.25,
      high: 78.75,
      direction: "ENE"
    },
    {
      low: 78.75,
      high: 101.25,
      direction: "E"
    },
    {
      low: 101.25,
      high: 123.75,
      direction: "ESE"
    },
    {
      low: 123.75,
      high: 146.25,
      direction: "SE"
    },
    {
      low: 146.25,
      high: 168.75,
      direction: "SSE"
    },
    {
      low: 168.75,
      high: 191.25,
      direction: "S"
    },
    {
      low: 191.25,
      high: 213.75,
      direction: "SSW"
    },
    {
      low: 213.75,
      high: 236.25,
      direction: "SW"
    },
    {
      low: 236.25,
      high: 258.75,
      direction: "WSW"
    },
    {
      low: 258.75,
      high: 281.25,
      direction: "W"
    },
    {
      low: 281.25,
      high: 303.75,
      direction: "WNW"
    },
    {
      low: 303.75,
      high: 326.25,
      direction: "NW"
    },
    {
      low: 326.25,
      high: 348.75,
      direction: "NNW"
    }
  ];

  if (bearing === 0) return "N";

  const compassPoint = _.find(compassValues, function(item) {
    return item.low < bearing && item.high >= bearing;
  });

  return compassPoint.direction;
}
