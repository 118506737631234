import React, { Component } from "react";
import UnitsButton from "./UnitsButton";

class TopMenu extends Component {
  render() {
    return (
      <div className="">
        <div className="flex justify-center w-full bg-grey-lightest">
          <div className="flex justify-center w-full text-grey-darker py-2">
            <div className="px-4">
              <UnitsButton
                unitsLabel="F"
                value="us"
                units={this.props.units}
                onClick={this.props.onClick}
              />
            </div>
            <div className="px-4">
              <UnitsButton
                unitsLabel="C"
                value="si"
                units={this.props.units}
                onClick={this.props.onClick}
              />
            </div>
            <button
              className="px-4 cursor-pointer font-bold text-grey-darker hover:text-blue"
              onClick={this.props.onClick}
              name="changeLocation"
            >
              Change Location
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TopMenu;
