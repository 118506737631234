import React, { Component } from "react";
import logo from "./../images/bh-logo-grey.gif";

class Footer extends Component {
  render() {
    const currently = this.props.currently;
    if (!currently) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-center pt-8 pb-4 bg-grey-lightest">
          <div className="">
            <a
              className="text-grey-darker no-underline"
              href="https://darksky.net/poweredby/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Powered by Dark Sky
            </a>
          </div>
        </div>

        <footer className="flex justify-center pt-4 pb-8 bg-grey-lightest">
          <div className="w-16">
            <a href="https://bob-humphrey.com">
              <img src={logo} alt="Logo" />
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
