import React, { Component } from "react";
import Observations from "../components/Observations.js";
import Header from "../components/Header.js";
import HourlyForecast from "../components/HourlyForecast.js";
import FiveDay from "../components/FiveDay";
import moment from "moment";

class ForecastDetail extends Component {
  render() {
    const cityData = this.props.cityData;
    if (!cityData) return null;
    const pathnames = this.props.location.pathname.split("/");
    if (!pathnames) return null;
    let day = pathnames.pop();
    if (!day) {
      day = "0";
    }
    let time = cityData.daily.data[day].time;
    let displayDate = moment.unix(time).format("dddd, MMMM D");

    return (
      <div className="">
        <Header
          componentWidth="w-5/6"
          status={displayDate}
          city={this.props.city}
          cityData={this.props.cityData}
          currently={this.props.currently}
          header={this.props.header}
          newLocation={this.props.newLocation}
          lookupLocations={this.props.lookupLocations}
          lookupLocationsMessage={this.props.lookupLocationsMessage}
          showBack={true}
          showSelectBox={false}
          units={this.props.units}
          onClick={this.props.onClick}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
        />

        <FiveDay
          componentWidth="w-5/6"
          currently={this.props.currently}
          cityData={this.props.cityData}
          day={day}
        />

        <Observations
          cityData={this.props.cityData}
          pageType="forecast"
          day={day}
          units={this.props.units}
        />

        <HourlyForecast
          componentWidth="w-5/6"
          cityData={this.props.cityData}
          day={day}
        />
      </div>
    );
  }
}

export default ForecastDetail;
