import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getIcon } from "../functions/getIcon.js";

class ForecastSummary extends Component {
  render() {
    const dailyData = this.props.dailyData;
    if (!dailyData) return null;
    const today = moment().format("dddd");
    const forecastSummaryDay = moment
      .unix(dailyData.time)
      .tz(this.props.timezone)
      .format("dddd");
    const dayOfWeek =
      today === forecastSummaryDay ? "Today" : forecastSummaryDay;
    const icon = getIcon("forecast", dailyData.icon);
    const iconImage = require("../images/" + icon);
    const summary = dailyData.summary;
    let displaySummary =
      summary.length > 80 ? summary.slice(0, 79) + "..." : summary;
    const bgColor =
      this.props.day === this.props.selectedDay
        ? "lg:bg-white"
        : "lg:bg-blue-lightest";

    return (
      <Link className="no-underline" to={"/forecast_detail/" + this.props.day}>
        <div
          className={
            bgColor +
            " bg-white hover:bg-white px-6 py-10 cursor-default lg:cursor-pointer"
          }
        >
          <div className="text-sm font-bold text-black">{dayOfWeek}</div>
          <div className="text-sm font-bold text-grey-dark">
            {moment
              .unix(dailyData.time)
              .tz(this.props.timezone)
              .format("MMMM D")}
          </div>
          <div className="text-sm text-grey-dark pt-4">
            {Math.ceil(dailyData.precipProbability * 100)}%{" "}
            {dailyData.precipType}
          </div>
          <div className="flex justify-center px-8 py-2">
            <img src={iconImage} alt="icon" />
          </div>
          <div className="text-sm text-grey-dark h-16">{displaySummary}</div>

          <div className="flex justify-between flex-row text-3xl lg:text-xl xl:text-3xl font-bold text-black">
            <div className="justify-start">
              <span className="">
                {Math.round(dailyData.temperatureMax)}&deg;&nbsp;
              </span>
              <span className="text-grey-dark">
                {Math.round(dailyData.temperatureMin)}&deg;
              </span>
            </div>
          </div>

          <div className="text-sm "></div>
        </div>
      </Link>
    );
  }
}

export default ForecastSummary;
