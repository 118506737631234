import React, { Component } from "react";
import { getIcon } from "../functions/getIcon.js";
import { getWindDirection } from "../functions/getWindDirection.js";
import Alerts from "./Alerts";

class CurrentLocation extends Component {
  render() {
    const currently = this.props.currently;
    if (!currently) {
      return null;
    }
    const cityData = this.props.cityData;
    if (!cityData) {
      return null;
    }

    const displayCity = this.props.city.replace(
      ", United States of America",
      ""
    );
    const sunrise = cityData.daily.data[0].sunriseTime;
    const sunset = cityData.daily.data[0].sunsetTime;
    const timezone = cityData.timezone;
    const icon = getIcon("current", currently.icon, sunrise, sunset, timezone);
    const iconImage = require("../images/" + icon);
    const windUnits = currently.units === "us" ? "mph" : "mps";
    const windDirection = getWindDirection(currently.windBearing);
    return (
      <div className={this.props.componentWidth + " flex flex-col lg:flex-row"}>
        <div className="flex flex-col lg:w-1/2 justify-center items-center bg-grey-lighter lg:h-48 px-6 pt-6 lg:p-0">
          <div className="text-2xl lg:text-3xl text-blue pb-4">
            Weather Forecast
          </div>
          <div className="text-normal lg:text-xl text-black font-bold pb-2 lg:pb-0">
            {displayCity}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:w-1/2 bg-grey-lighter lg:h-48 px-6 pb-6 lg:p-0">
          <div className="lg:w-1/6"></div>
          <div className="flex lg:w-1/3 justify-center items-center">
            <img className="w-32" src={iconImage} alt="icon" />
          </div>
          <div className="flex flex-col lg:w-1/3 justify-center items-center lg:items-start">
            <div className="font-bold mb-1">{currently.time}</div>
            <Alerts cityData={this.props.cityData} />
            <div className="text-grey-dark text-sm">{currently.summary}</div>
            <div className="text-3xl font-bold">
              {currently.temperature}&deg;
            </div>
            <div className="text-grey-dark text-sm mb-1">
              Feels like: {currently.apparentTemperature}&deg;
            </div>
            <div className="text-grey-dark text-sm mb-1">
              Humidity: {Math.round(currently.humidity * 100)} %
            </div>
            <div className="text-grey-dark text-sm">
              Wind: {Math.round(currently.windSpeed)} {windUnits}{" "}
              {windDirection}
            </div>
          </div>
          <div className="lg:w-1/6"></div>
        </div>
      </div>
    );
  }
}

export default CurrentLocation;
