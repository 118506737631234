import moment from "moment-timezone";

// The Dark Sky API suggests an icon for each forecast.
// This function maps their suggestion to our collection
// of icons.

export function getIcon(display, icon, sunrise, sunset, timezone) {
  let iconImage;
  let dayNight = "day";

  if (display === "current") {
    // Convert sunrise and sunset to local time
    const sunriseLocal = moment.unix(sunrise).tz(timezone);
    const sunsetLocal = moment.unix(sunset).tz(timezone);
    if (moment().isBefore(sunriseLocal)) {
      dayNight = "night";
    } else if (moment().isAfter(sunsetLocal)) {
      dayNight = "night";
    } else {
      dayNight = "day";
    }
  }

  switch (icon) {
    case "clear-day":
      iconImage = "clear_day.png";
      break;
    case "clear-night":
      iconImage = "clear_night.png";
      break;
    case "rain":
      iconImage = "rain_" + dayNight + ".png";
      break;
    case "snow":
      iconImage = "snow_" + dayNight + ".png";
      break;
    case "sleet":
      iconImage = "hail.png";
      break;
    case "wind":
      iconImage = "wind_" + dayNight + ".png";
      break;
    case "fog":
      iconImage = "fog_" + dayNight + ".png";
      break;
    case "cloudy":
      iconImage = "cloudy.png";
      break;
    case "partly-cloudy-day":
      iconImage = "partly_cloudy_day.png";
      break;
    case "partly-cloudy-night":
      iconImage = "partly_cloudy_night.png";
      break;
    default:
      iconImage = "partly_cloudy_day.png";
      break;
  }

  return iconImage;
}
