import React, { Component } from 'react'
import { getWindDirection } from '../functions/getWindDirection.js'
import moment from 'moment-timezone'

class HourlyForecastRow extends Component {

  render() {

      const bgColor = this.props.index % 2 ? 'bg-grey-light' : 'bg-white'
      const data = this.props.data
      if (!data) return null
      const timezone = this.props.timezone
      const time = moment.unix(data.time).tz(timezone).format('h:mm a')
      const temp = Math.round(data.temperature)
      const feelsLike = Math.round(data.apparentTemperature)
      const pctPrecip = Math.round(data.precipProbability * 100)
      const cloudCover = Math.round(data.cloudCover * 100)
      const windSpeed = Math.round(data.windSpeed)
      const windDirection = getWindDirection(data.windBearing)

    return (
      <tr>
        <td className={"w-1/10 p-3 " + bgColor}>
          {time}
        </td>
        <td className={"w-1/5 p-3 " + bgColor}>
          {data.summary}
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {temp}&deg;
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {feelsLike}&deg;
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {pctPrecip}%
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {data.precipIntensity}
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {cloudCover}%
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {windSpeed}
        </td>
        <td className={"w-1/10 p-3 " + bgColor}>
          {windDirection}
        </td>
      </tr>
    )
  }
}

export default HourlyForecastRow
