import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./css/tailwind.css";
import Footer from "./components/Footer.js";
import ForecastDetail from "./components/ForecastDetail.js";
import ErrorNotice from "./components/ErrorNotice.js";
import moment from "moment";
import _ from "lodash";
import locations from "./data/locations.json";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: {
        city: "Kent, Ohio",
        latitude: 41.153667,
        longitude: -81.357887,
        timezone: "America/New_York"
      },
      units: "us",
      currently: null,
      cityData: null,
      error: null,
      day: 0,
      header: "current location",
      newLocation: "",
      lookupLocations: [],
      lookupLocationsMessage: ""
    };
  }

  getGeoCodeData = location => {
    const encodedLocation = encodeURI(location);
    const fetchUrl = "https://api.bob-humphrey.com/opencage/" + encodedLocation;
    let locations = [];

    fetch(fetchUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        if (data.total_results === 0) {
          this.setState({
            lookupLocations: [],
            lookupLocationsMessage: "No locations found."
          });
        }
        if (data.total_results > 0) {
          data.results.forEach(element => {
            const location = {
              city: element.formatted,
              latitude: element.geometry.lat,
              longitude: element.geometry.lng,
              timezone: element.annotations.timezone.name
            };
            locations.push(location);
            this.setState({
              lookupLocations: locations,
              lookupLocationsMessage: ""
            });
          });
        }
      })
      .catch(error => this.setState({ error }));
  };

  getForecastData = (location, units) => {
    // The Lumen API app has a problem with periods in the route
    // parameters.  Change the periods to X's, and then the API will
    // change them back to periods.
    const latitude = String(location.latitude);
    const longitude = String(location.longitude);
    const modLatitude = latitude.replace(".", "X");
    const modLongitude = longitude.replace(".", "X");
    const fetchUrl =
      "https://api.bob-humphrey.com/darksky/" +
      modLatitude +
      "/" +
      modLongitude +
      "/" +
      units;

    fetch(fetchUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.updateCityDataState(data, location);
      })
      .catch(error => this.setState({ error }));
  };

  updateCityDataState = (data, location) => {
    let currently = {};
    currently["temperature"] = Math.round(data.currently.temperature);
    currently["apparentTemperature"] = Math.round(
      data.currently.apparentTemperature
    );
    currently["summary"] = data.currently.summary;
    currently["icon"] = data.currently.icon;
    const now = moment().tz(location.timezone);
    currently["time"] = now.format("dddd h:mm A");
    currently["unixTime"] = now.unix();
    currently["humidity"] = data.currently.humidity;
    currently["windSpeed"] = data.currently.windSpeed;
    currently["windBearing"] = data.currently.windBearing;
    currently["units"] = this.state.units;
    this.setState({
      currently: currently,
      cityData: data
    });
  };

  handleChange = changeEvent => {
    switch (changeEvent.target.name) {
      case "city":
        // DON'T USE STATE DATA TO FETCH FROM THE API
        const location = _.find(locations, function(l) {
          return l.city === changeEvent.target.value;
        });
        this.getForecastData(location, this.state.units);
        this.setState({
          location: location
        });
        break;
      case "changeLocation":
        const newLocation = changeEvent.target.value;
        this.setState({ newLocation: newLocation });
        break;
      default:
        break;
    }
  };

  handleClick = clickEvent => {
    switch (clickEvent.target.name) {
      case "units":
        if (clickEvent.target.value === this.state.units) {
          break;
        }
        this.getForecastData(this.state.location, clickEvent.target.value);
        this.setState({
          units: clickEvent.target.value
        });
        break;
      case "changeLocation":
        this.setState({
          newLocation: "",
          lookupLocations: [],
          header: "change location"
        });
        break;
      case "cancelChangeLocation":
        this.setState({
          header: "current location"
        });
        break;
      case "selectLocation":
        const selection = clickEvent.target.value;
        const newLocation = this.state.lookupLocations[selection];
        this.getForecastData(newLocation, this.state.units);
        this.setState({
          location: newLocation,
          header: "current location"
        });
        break;
      case "lookupLocations":
        this.getGeoCodeData(this.state.newLocation);
        break;
      default:
        break;
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.getGeoCodeData(this.state.newLocation);
    }
  };

  componentDidMount() {
    this.getForecastData(this.state.location, this.state.units);
  }

  render() {
    return (
      <Router>
        <div>
          <Route
            exact
            path="/"
            render={props => (
              <ForecastDetail
                {...props}
                city={this.state.location.city}
                cityData={this.state.cityData}
                currently={this.state.currently}
                header={this.state.header}
                newLocation={this.state.newLocation}
                lookupLocations={this.state.lookupLocations}
                lookupLocationsMessage={this.state.lookupLocationsMessage}
                units={this.state.units}
                onClick={this.handleClick}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
            )}
          />

          <Route
            path="/forecast_detail/"
            render={props => (
              <ForecastDetail
                {...props}
                city={this.state.location.city}
                cityData={this.state.cityData}
                currently={this.state.currently}
                header={this.state.header}
                newLocation={this.state.newLocation}
                lookupLocations={this.state.lookupLocations}
                lookupLocationsMessage={this.state.lookupLocationsMessage}
                units={this.state.units}
                onClick={this.handleClick}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
            )}
          />

          {this.state.error ? <ErrorNotice /> : null}

          <Footer currently={this.state.currently} />
        </div>
      </Router>
    );
  }
}

export default App;
