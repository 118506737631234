import React, { Component } from "react";
import ForecastSummary from "../components/ForecastSummary.js";

class FiveDay extends Component {
  render() {
    const cityData = this.props.cityData;
    const selectedDay = this.props.day;
    if (!cityData) return null;
    const dailyData = cityData.daily.data;
    if (!dailyData) return null;

    return (
      <div className={"flex justify-center w-full bg-grey-lightest"}>
        <div className={this.props.componentWidth}>
          <div className="flex flex-col lg:flex-row bg-grey-lightest">
            <div className="lg:w-1/6 flex-1 mb-2 lg:mb-0">
              <ForecastSummary
                dailyData={dailyData[0]}
                timezone={cityData.timezone}
                day="0"
                selectedDay={selectedDay}
              />
            </div>
            <div className="lg:w-1/6 flex-1 mb-2 lg:mb-0">
              <ForecastSummary
                dailyData={dailyData[1]}
                timezone={cityData.timezone}
                day="1"
                selectedDay={selectedDay}
              />
            </div>
            <div className="lg:w-1/6 flex-1 mb-2 lg:mb-0">
              <ForecastSummary
                dailyData={dailyData[2]}
                timezone={cityData.timezone}
                day="2"
                selectedDay={selectedDay}
              />
            </div>
            <div className="lg:w-1/6 flex-1 mb-2 lg:mb-0">
              <ForecastSummary
                dailyData={dailyData[3]}
                timezone={cityData.timezone}
                day="3"
                selectedDay={selectedDay}
              />
            </div>
            <div className="lg:w-1/6 flex-1 mb-2 lg:mb-0">
              <ForecastSummary
                dailyData={dailyData[4]}
                timezone={cityData.timezone}
                day="4"
                selectedDay={selectedDay}
              />
            </div>
            <div className="lg:w-1/6 flex-1 mb-2 lg:mb-0">
              <ForecastSummary
                dailyData={dailyData[5]}
                timezone={cityData.timezone}
                day="5"
                selectedDay={selectedDay}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiveDay;
