import React, { Component } from "react";

class UnitsButton extends Component {
  render() {
    let classNameProps;
    if (
      (this.props.units === "us" && this.props.unitsLabel === "F") ||
      (this.props.units === "si" && this.props.unitsLabel === "C")
    ) {
      classNameProps = "text-yellow-dark font-bold cursor-default";
    } else {
      classNameProps =
        "text-grey-darker hover:text-blue font-bold cursor-pointer";
    }

    return (
      <button
        className={classNameProps}
        name="units"
        value={this.props.value}
        onClick={this.props.onClick}
      >
        {this.props.unitsLabel}
      </button>
    );
  }
}

export default UnitsButton;
