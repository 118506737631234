import React, { Component } from "react";
import TopMenu from "./TopMenu";
import CurrentLocation from "./CurrentLocation";
import ChangeLocation from "./ChangeLocation";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCurrentLocation: "flex",
      showChangeLocation: "hidden"
    };
  }

  render() {
    const currently = this.props.currently;
    if (!currently) {
      return null;
    }
    const cityData = this.props.cityData;
    if (!cityData) {
      return null;
    }
    let showCurrentLocation = "flex";
    let showChangeLocation = "hidden";
    if (this.props.header === "change location") {
      showCurrentLocation = "hidden";
      showChangeLocation = "flex";
    }

    return (
      <div className="">
        <TopMenu units={this.props.units} onClick={this.props.onClick} />

        <div
          className={
            showCurrentLocation + " justify-center w-full bg-grey-lightest"
          }
        >
          <CurrentLocation
            city={this.props.city}
            componentWidth={this.props.componentWidth}
            currently={currently}
            cityData={cityData}
          />
        </div>

        <div
          className={
            showChangeLocation + " justify-center w-full bg-grey-lightest"
          }
        >
          <ChangeLocation
            componentWidth={this.props.componentWidth}
            newLocation={this.props.newLocation}
            lookupLocations={this.props.lookupLocations}
            lookupLocationsMessage={this.props.lookupLocationsMessage}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            onClick={this.props.onClick}
          />
        </div>
      </div>
    );
  }
}

export default Header;
