import React, { Component } from "react";
import LocationButton from "./LocationButton";

class ChangeLocation extends Component {
  render() {
    const locations = this.props.lookupLocations;
    return (
      <div
        className={
          this.props.componentWidth +
          " flex flex-col lg:flex-row bg-grey-lighter"
        }
      >
        <div className="flex flex-col lg:w-1/2 justify-center self-center bg-grey-lighter lg:px-16 pt-6 lg:py-0 lg:h-48">
          <div className="flex w-full justify-center text-2xl text-grey-darker pb-6">
            Change Location
          </div>
          <div className="flex flex-col lg:flex-row justify-between self-center lg:w-5/6 pb-6">
            <input
              className="flex justify-start bg-white lg:w-5/6 px-4 py-2 mr-3 mb-6 lg:mb-0"
              type="text"
              name="changeLocation"
              value={this.props.newLocation}
              onChange={this.props.onChange}
              onKeyDown={this.props.onKeyDown}
            />
            <button
              className="flex justify-end self-center bg-grey hover:bg-grey-darkest hover:text-white font-bold rounded py-2 px-6"
              name="lookupLocations"
              onClick={this.props.onClick}
            >
              Find
            </button>
          </div>
          <div className="flex justify-center w-full ">
            <button
              className="flex justify-end self-center text-grey-darker bg-grey-lighter hover:bg-black hover:text-white font-bold rounded border border-grey-darker py-2 px-6"
              name="cancelChangeLocation"
              onClick={this.props.onClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-around lg:w-1/2 bg-grey-lighter py-6 ">
          {locations.length > 0 && (
            <LocationButton
              key="0"
              city={locations[0].city}
              value="0"
              onClick={this.props.onClick}
            />
          )}
          {locations.length > 1 && (
            <LocationButton
              key="1"
              city={locations[1].city}
              value="1"
              onClick={this.props.onClick}
            />
          )}
          {locations.length > 2 && (
            <LocationButton
              key="2"
              city={locations[2].city}
              value="2"
              onClick={this.props.onClick}
            />
          )}
          {locations.length > 3 && (
            <LocationButton
              key="3"
              city={locations[3].city}
              value="3"
              onClick={this.props.onClick}
            />
          )}
          {locations.length > 4 && (
            <LocationButton
              key="4"
              city={locations[4].city}
              value="4"
              onClick={this.props.onClick}
            />
          )}
          {locations.length > 5 && (
            <LocationButton
              key="5"
              city={locations[5].city}
              value="5"
              onClick={this.props.onClick}
            />
          )}
          {locations.length > 6 && (
            <LocationButton
              key="6"
              city={locations[6].city}
              value="6"
              onClick={this.props.onClick}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ChangeLocation;
